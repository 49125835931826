

.ql-editor {
    height: 200px;
    background-color: #0d0d0d;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    font-family: BaiJamjuree;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    
}

.ql-editor > p {
    color: #FFFFFF;
    opacity: 0.6;
    display: block;
    
}

.ql-editor > h2, 
.ql-editor > h3 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 600;
    color: #FFFFFF;
    margin: 48px 0 32px 0;
    padding: 0;
}
.ql-omega:after {
    content: "Ω";
    width: 100%;
    height: 100%;
  }
